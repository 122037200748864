/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import CategoryBtn from './CategoryBtn';
import FilterItem from './FilterItem';
import projectFilterData from './projectFilterData';

function ProjectFilter() {
    // STATES
    const [category, setCategory] = useState('all');
    const [filteredItem, setFilteredItem] = useState([]);

    useEffect(() => {
        category === 'all'
            ? setFilteredItem(projectFilterData)
            : setFilteredItem(projectFilterData.filter((data) => data.category === category));
    }, [category]);

    return (
        <section className="portfolio-wrapper-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12 text-center">
                        <div className="section-title-2 my-2">
                            <h3>PROJECTS</h3>
                        </div>
                    </div>
                </div>
                
                <div className="row grid">
                    {filteredItem.map(
                        (item, length) =>
                            length <= '9' && (
                                <FilterItem
                                    key={item.id}
                                    image={item.image}
                                    link={item.link}
                                    heading={item.heading}
                                    category={item.category}
                                />
                            )
                    )}
                </div>
            </div>
        </section>
    );
}

export default ProjectFilter;
