import { v4 as uuidv4 } from 'uuid';
import Img1 from '../../assets/img/project/project1.jpg';
import Img2 from '../../assets/img/project/project2.jpg';
import Img3 from '../../assets/img/project/project3.jpg';
import Img4 from '../../assets/img/project/project4.jpg';
import Img5 from '../../assets/img/project/project5.jpg';
import Img6 from '../../assets/img/project/project6.jpg';


const portfolioOneData = [
    {
        id: uuidv4(),
        image: Img1,
        category: 'Event',
        heading: 'EBMT - 2018',
        btnText: 'Details',
    },

    {
        id: uuidv4(),
        image: Img2,
        category: 'Equipments',
        heading: 'CliniMACS Devices',
    },
    
    {
        id: uuidv4(),
        image: Img3,
        category: 'Medicine',
        heading: 'Clinical Reagents',
    },
    
    {
        id: uuidv4(),
        image: Img4,
        category: 'Experiments',
        heading: 'Cytocare Experiments',
    },

    {
        id: uuidv4(),
        image: Img5,
        category: 'Event',
        heading: 'Cytocare Events',
        btnText: 'Details',
    },

    {
        id: uuidv4(),
        image: Img6,
        category: 'Event',
        heading: 'Cytocare Events',
        btnText: 'Details',
    },
];

export default portfolioOneData;
