import React, { useState } from 'react';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';

function AboutFeatured() {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <section className="about-featured-wrapper pb-5">
                <div className="container">
                    <div className="row">
                        <div class="col-md-12">
                            <div class="section-heading text-center">
                                <h4>Our Team</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">G. Chandra Mohan: </h5>
                                    <p class="card-text">Chandra Mohan is a young and enthusiastic post-graduate (M.Tech – Environment Management) from JNTU, Hyderabad & entrepreneur processing in-depth business experience in various companies carrying out activities of Environment, Civil and Bio medical waste Management(BMW) in Warangal. He has almost 17 years of experience in Environmental Field. He has vast experience of 15 Years in the promotion of business and the marketing of BMW facilities. As a founder partner/ director, he has established BMW treatment Facility in Warangal Established in 2003.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">K. Ramesh:</h5>
                                    <p class="card-text">Ramesh is a young and enthusiastic post-graduate (M. Sc Environmental Sciences) from Kakatiya University with 15 years of total experience in the field of Environment.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">Mr. Subba Reddy:</h5>
                                    <p class="card-text">He did masters in chemical engineering from IIT Madras. He has more than 10 years of design and execution experience in water, sewage and effluent treatment plants. He is expert in green technology and executed more than 70 sewage treatment projects all over India. He is adept in project design, management and procurement. He will be involved in the implementation of the wastewater treatment projects.</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutFeatured;
