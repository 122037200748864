import React from 'react';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';

function Topbar() {
    return (
        <div className="top-bar d-none d-md-block">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-12">
                    <AiOutlineMail
                        style={{
                            fontSize: '16px',
                            color: 'white',
                            marginTop: '-5px',
                            marginRight: '5px',
                        }}
                    />
                    <a href="mailto:chandukee@gmail.com">chandukee@gmail.com</a>
                    </div>
                    <div className="col-lg-3 col-12 d-none d-lg-block text-lg-right">
                    <AiOutlinePhone
                        style={{
                            fontSize: '16px',
                            color: 'white',
                            marginTop: '-5px',
                            marginRight: '5px',
                        }}
                    />{' '}
                    <a href="tel:+919849009040">+91 9849009040</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Topbar;
