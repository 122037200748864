import React from 'react';
import Cta from '../components/Cta';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import Hero1 from '../components/Hero1';
import Portfolio1 from '../components/Portfolio1';
import Services1 from '../components/Services1';

function Home1() {
    return (
        <>
            <Header1 />
            <div className='container section-padding pt-4'>
                <div className='row'>
                    <div className='col-md-8 col-12'>
                        <Hero1 />
                    </div>
                    <div className='col-md-4 col-12'>
                        <p className='mb-3 text-justify'>
                        Our organization M/s. Kakatiya Enviro Engineers located in Hanamkonda district, Telangana State has been launched in the year 2010. The strong keys of our organization are the established network, the availability of strong experience, stable and sustained development of our organization.
                        </p>
                        <p className='mb-3 text-justify'>
                        We, M/s. Kakatiya Enviro Engineers, an environmental consultancy with highly qualified and experienced professionals in the field of civil, environmental, Bio-Medical Waste Management and electro-mechanical sectors since 2010. We have built and provided our O&M services for several Sewage and Effluent Treatment Plants for various institutions and industries.
                        </p>
                        
                    </div>
                </div>
            </div>
            <Services1 />
            {/* <div className='container section-padding'>
                <div className="row g-3">
                    <div className="col-12">
                        <div className="section-title text-center">
                            <h3>Empowering Discovery. Advancing Cell Therapy</h3>
                        </div>
                    </div>
                    <div className='col-md-3 col-12'>
                        <div className='text-center p-3 d-flex' style={{backgroundColor: "#aec738", color: "#fff", borderRadius: "8px", height: "90px"}}>
                            <div className="justify-content-center align-self-center w-100">
                            Cell & Gene Therapy
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 col-12'>
                        <div className='text-center p-3 d-flex' style={{backgroundColor: "#fdcb0a", color: "#333", borderRadius: "8px", height: "90px"}}>
                            <div className="justify-content-center align-self-center w-100">Graft Engineering</div>
                            
                        </div>
                    </div>
                    <div className='col-md-3 col-12'>
                        <div className='text-center p-3 d-flex' style={{backgroundColor: "#982c75", color: "#fff", borderRadius: "8px", height: "90px"}}>
                            <div className="justify-content-center align-self-center w-100">
                            Cell Processing & Cryopreservation
                            </div>
                            
                        </div>
                    </div>
                    <div className='col-md-3 col-12'>
                        <div className='text-center p-3 d-flex' style={{backgroundColor: "#6b6d6c", color: "#fff", borderRadius: "8px", height: "90px"}}>
                            <div className="justify-content-center align-self-center w-100">
                            T Cell Depletion in Grafting Engineering
                            </div>
                            
                        </div>
                    </div>
                    <div className='col-md-3 col-12'>
                        <div className='text-center p-3 d-flex' style={{backgroundColor: "#cda367", color: "#333", borderRadius: "8px", height: "90px"}}>
                            <div className="justify-content-center align-self-center w-100">
                            Virus Specific T Cells For immunotherapy
                            </div>
                            
                        </div>
                    </div>
                    <div className='col-md-3 col-12'>
                        <div className='text-center p-3 d-flex' style={{backgroundColor: "#a45c50", color: "#fff", borderRadius: "8px", height: "90px"}}>
                            <div className="justify-content-center align-self-center w-100">
                            Dentric Cells For Immunotherapy
                            </div>
                           
                        </div>
                    </div>
                    <div className='col-md-3 col-12'>
                        <div className='text-center p-3 d-flex' style={{backgroundColor: "#2b80c3", color: "#fff", borderRadius: "8px", height: "90px"}}>
                            <div className="justify-content-center align-self-center w-100">
                            NK Cells For Immunotherapy
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 col-12'>
                        <div className='text-center p-3 d-flex' style={{backgroundColor: "#5d8c44", color: "#fff", borderRadius: "8px", height: "90px"}}>
                            <div className="justify-content-center align-self-center w-100">
                            Stem Cell Enrichment in Engineering
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-12'>
                        <div className='text-center p-3 d-flex' style={{backgroundColor: "#6f7889", color: "#fff", borderRadius: "8px", height: "90px"}}>
                            <div className="justify-content-center align-self-center w-100">
                            Gene Engineered Hemotopoietic Stem Cell (HSCs)
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-12'>
                        <div className='text-center p-3 d-flex' style={{backgroundColor: "#f38222", color: "#fff", borderRadius: "8px", height: "90px"}}>
                            <div className="justify-content-center align-self-center w-100">
                                Adherent Cell Culture System
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <Portfolio1 />
            {/* <div className='container section-padding'>
                <div className="row">
                    <div className="col-12">
                        <div className="section-title text-center">
                            <h3>Clinimac Protocol</h3>
                        </div>
                    </div>
                </div>
                <div className="row g-3 justify-content-md-center">
                    <div className="col-md-4 col-12" style={{maxHeight: "480px"}}>
                        <img src={HeroBg2} className="w-100" style={{height: "350px"}} />
                    </div>
                    <div className="col-md-4 col-12" style={{maxHeight: "480px"}}>
                        <img src={HeroBg8} className="w-100" style={{height: "350px"}} />
                    </div>
                    <div className="col-md-4 col-12" style={{maxHeight: "480px"}}>
                        <img src={HeroBg10} className="w-100" style={{height: "350px"}} />
                    </div>
                    <div className="col-md-4 col-12" style={{maxHeight: "480px"}}>
                        <img src={HeroBg11} className="w-100" style={{height: "350px"}} />
                    </div>
                    <div className="col-md-4 col-12" style={{maxHeight: "480px"}}>
                        <img src={HeroBg13} className="w-100" style={{height: "350px"}} />
                    </div>
                    <div className="col-md-4 col-12" style={{maxHeight: "480px"}}>
                        <img src={HeroBg14} className="w-100" style={{height: "350px"}} />
                    </div>
                </div>
            </div> */}
            <Footer1 />
        </>
    );
}

export default Home1;
