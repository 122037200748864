import React from 'react';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';

import Service1 from '../assets/img/services/service1.jpg';
import Service2 from '../assets/img/services/service2.jpg';
import Service3 from '../assets/img/services/service3.jpg';
import Service4 from '../assets/img/services/service4.jpg';
import Service5 from '../assets/img/services/service5.jpg';


function Projects() {
    return (
        <>
            <Header1 />
            <section className='blog-wrapper news-wrapper section-padding'>

                <div className='container'>
                    <div className='row'>
                        <div className="col-md-12">
                            <div className="news-content">
                                <div className="section-heading marginbot20">
                                    <p><strong>Design, supply, erection and commissioning of WTPs, STPs, RO Plants and ETPs. </strong></p><h5></h5>
                                </div>
                                <div className="section-body d-flex flex-wrap g-2">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img src={Service1} alt="" srcset="" />
                                        </div>
                                        <div className="col-md-8">
                                            <p className="text-justify">
                                                The enterprise possesses the technical capacity to offer and install this device WTPs, STPs, RO Plants  & ETPs to the fullest delight of this the customer. All our personnel participants are properly versed with all of the enterprise's laid parameters and ensure to render offerings so. Our experts are specialized in generating erection and commissioning offerings in keeping with the client's requirements.
                                            </p>
                                            <p className="text-justify">
                                                Our water remedy strategies are extraordinarily streamlined, tailored layouts, cellular demands much less footprint to adapt, power efficient. Our provider is widely identified with the aid of using our treasured clients due to its flawlessness and hassle-unfastened implementation. These offerings were left with the aid of using revolutionary generations and superior machines.
                                            </p>
                                            <p className="text-justify">
                                                Our provided gadget is generally applied in residential and commercial regions to dispose of issues. We're properly aware of the need of purchasers and businesses. We've undertaken previous such endeavors with terrific success. Pros are absolutely properly versed in a maximum of the sides of this provider which lets them finish this timely. We're properly aware of the want of purchasers and businesses. We've undertaken previous such endeavors with terrific success.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider my-2"></div>

                                <div className="section-heading marginbot20">
                                    <p><strong>Tertiary Treatment Plants.</strong></p><h5></h5>
                                </div>
                                <div className="section-body d-flex flex-wrap g-2">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img src={Service2} alt="" srcset="" />
                                        </div>
                                        <div className="col-md-8">
                                            <p className="text-justify">Tertiary remedy is the next wastewater remedy method after secondary remedy. This step eliminates cussed contaminants that secondary remedy become now no longer capable of smooth up. Wastewater effluent turns into even cleanser on this remedy method thru the usage of more potent and extra superior remedy systems. Tertiary remedy technology may be the extensions of traditional secondary organic remedy to similarly stabilize oxygen-disturbing materials withinside the wastewater or to do away with nitrogen and phosphorus. Tertiary remedy may additionally contain physical-chemical separation strategies such as carbon adsorption, flocculation/precipitation, membranes for superior filtration, ion exchange, dechlorination, and opposite osmosis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider my-2"></div>

                                <div className="section-heading marginbot20">
                                    <p><strong>Operation and maintenance of ETPs, STPs, WTPs, RO plants & Swimming pools</strong>:</p><h5></h5>
                                </div>
                                <div className="section-body d-flex flex-wrap g-2">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img src={Service3} alt="" srcset="" />
                                        </div>
                                        <div className="col-md-8">
                                            <p className="text-justify">Operating and keeping an effluent remedy plant ETP or Sewage remedy plant STP is a difficult activity due to numerous fitness risks. Plant supervisor and operators must be bodily match and mentally alert. They additionally must receive assigned paintings gladly and entire them independently. While following those duties one must by no means overlook approximately protection of others or themselves as they may be constantly uncovered to excessive noise degree and ugly odors. In this form of paintings, bodily presence matters, regularly in unclean environment. As ETP or STP plant desires to be run 24 X 7 consequently they want to paintings for eight hrs in rotating shifts regularly with out weekends or holidays. Sometimes they required to paintings overtime.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider my-2"></div>

                                <div className="section-heading marginbot20">
                                    <p><strong>Water and waste water monitoring and analysis</strong></p><h5></h5>
                                </div>
                                <div className="section-body d-flex flex-wrap g-2">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img src={Service4} alt="" srcset="" />
                                        </div>
                                        <div className="col-md-8">
                                            <p className="text-justify">Water is the supply and foundation of all life. It is essential for metabolism and is the maximum sizeable victuals. As a solvent and transporting agent, water includes the maximum crucial minerals and nutrients, and also, increasingly, risky pollutants, which bioaccumulate in terrestrial or aquatic organisms. Impurities may be normally discovered in all styles of water- herbal and dealt with consuming water for procedure and cooling water to the water to be used in meals manufacturing and pharmaceuticals.</p>
                                            <p className="text-justify">High natural infection in water might also additionally act as a challenge to numerous business operations, main to problems. For instance, an extra of natural rely can foster microbiological increase or, while disinfecting consuming water, inspire the presence of unwanted byproducts.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider my-2"></div>

                                <div className="section-heading marginbot20">
                                    <p><strong>Supply of biomedical waste handling material.</strong></p><h5></h5>
                                </div>
                                <div className="section-body d-flex flex-wrap g-2">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img src={Service5} alt="" srcset="" />
                                        </div>
                                        <div className="col-md-8">
                                            <ul>
                                                <li>Colour coded Non Chlorinated Poly ethylene bags with QR codes for handling biomedical waste.</li>
                                                <li>Colour coded bins of different sizes</li>
                                                <li>Puncture proof containers for handling sharp material</li>
                                                <li>Posters creating awareness regarding segregation of bio medical waste</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider my-2"></div>



                            </div>
                        </div>
                    </div>


                </div>
            </section>
            <Footer1 />
        </>
    );
}

export default Projects;
