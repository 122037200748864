/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import contactData from './contactData';
import ContactItem from './ContactItem';
import Map from './Map';

function ContactUs() {
    return (
        <section className="contact-page-wrap section-padding  pb-4 pt-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="single-contact-card card1">
                            <div className="top-part">
                                <div className="icon"><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 110.8V792H136V270.8l-27.6-21.5 39.3-50.5 42.8 33.3h643.1l42.8-33.3 39.3 50.5-27.7 21.5zM833.6 232L512 482 190.4 232l-42.8-33.3-39.3 50.5 27.6 21.5 341.6 265.6a55.99 55.99 0 0 0 68.7 0L888 270.8l27.6-21.5-39.3-50.5-42.7 33.2z"></path></svg></div>
                                <div className="title">
                                    <h4>Email Address</h4>
                                </div>
                            </div>
                            <div className="bottom-part">
                                <div className="info">
                                    <p><a href="mailto:chandukee@gmail.com">chandukee@gmail.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="single-contact-card card1">
                            <div className="top-part">
                                <div className="icon">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M877.1 238.7L770.6 132.3c-13-13-30.4-20.3-48.8-20.3s-35.8 7.2-48.8 20.3L558.3 246.8c-13 13-20.3 30.5-20.3 48.9 0 18.5 7.2 35.8 20.3 48.9l89.6 89.7a405.46 405.46 0 0 1-86.4 127.3c-36.7 36.9-79.6 66-127.2 86.6l-89.6-89.7c-13-13-30.4-20.3-48.8-20.3a68.2 68.2 0 0 0-48.8 20.3L132.3 673c-13 13-20.3 30.5-20.3 48.9 0 18.5 7.2 35.8 20.3 48.9l106.4 106.4c22.2 22.2 52.8 34.9 84.2 34.9 6.5 0 12.8-.5 19.2-1.6 132.4-21.8 263.8-92.3 369.9-198.3C818 606 888.4 474.6 910.4 342.1c6.3-37.6-6.3-76.3-33.3-103.4zm-37.6 91.5c-19.5 117.9-82.9 235.5-178.4 331s-213 158.9-330.9 178.4c-14.8 2.5-30-2.5-40.8-13.2L184.9 721.9 295.7 611l119.8 120 .9.9 21.6-8a481.29 481.29 0 0 0 285.7-285.8l8-21.6-120.8-120.7 110.8-110.9 104.5 104.5c10.8 10.8 15.8 26 13.3 40.8z"></path></svg>
                                </div>
                                <div className="title">
                                    <h4>Phone Number</h4>
                                </div>
                            </div>
                            <div className="bottom-part">
                                <div className="info">
                                    <p><a href="tel:+919849009040">+91 9849009040</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="single-contact-card card1">
                            <div className="top-part">
                                <div className="icon">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 384 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>
                                </div>
                                <div className="title">
                                    <h4>Office Address</h4>
                                </div>
                            </div>
                            <div className="bottom-part">
                                <div className="info">
                                    <p> # 1-7-600/1, Subedari, Hanamkonda, Warangal, Telangana - 506001</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="contact-map-wrap">
                            <div id="map">
                                <iframe
                                    title="map"
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15178.68437184487!2d79.5481639!3d17.994033!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x24508f54f946db5f!2sKakatiya%20Enviro%20Engineers!5e0!3m2!1sen!2sin!4v1669517083369!5m2!1sen!2sin"
                                    frameBorder="0"
                                    style={{
                                        border: '0',
                                        width: '100%',
                                    }}
                                    allowFullScreen=""
                                    aria-hidden="false"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactUs;
