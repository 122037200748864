import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import portfolioOneData from './porfolioOneData';
import PortfolioOneSlide from './PortfolioOneSlide';
import ProjectFilter from '../../components/ProjectFilter';

SwiperCore.use([Navigation]);

function Porfolio1() {
    return (
        <ProjectFilter />
    );
}

export default Porfolio1;
