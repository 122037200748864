import { v4 as uuidv4 } from 'uuid';

const servicesOneData = [

    {
        id: uuidv4(),
        heading: 'Operation & maintenance of ETPs, STPs, WTPs, RO plants Swimming pools.',
        btnText: 'Learn More',
        link: '/services',
        items: [
            'Operating and maintaining effluent treatment plant ETP or Sewage treatment plant STP is a challenging job because of various health risks. Plant manager and operators should be physically fit and mentally alert. They also should accept assigned work gladly and complete them independently. While following these responsibilities one should never forget about safety of others or themselves as they are continuously exposed to high noise level and unpleasant odors. In this type of work, physical presence matters, often in unclean environment. As ETP or STP plant needs to be run 24 X 7 therefore they need to work for 8 hrs in rotating shifts often without weekends or holidays. Sometimes they required to work overtime.'
        ]
    },
    {
        id: uuidv4(),
        heading: 'Design, supply, erection and commissioning of WTPs, STPs and ETPs.',
        btnText: 'Learn More',
        link: '/services',
        items: [
            'The enterprise possesses the technical capacity to offer and installation this device to the fullest delight of this customer. All our personnel participants are properly versed with all of the enterprise laid parameters and ensure to render offerings so. Our experts are specialised in generating erection and commissioning offerings in keeping with the clients requirements.'
        ]
    },
    
    
    {
        id: uuidv4(),
        heading: 'Water and waste water monitoring and analysis.',
        btnText: 'Learn More',
        link: '/services',
        items: [
            'Water is the source and basis of all life. It is vital for metabolism and is the most significant victuals. As a solvent and transporting agent, water contains the most important minerals and nutrients, and also, increasingly, dangerous pollutants, which bioaccumulate in terrestrial or aquatic organisms. Impurities can be generally found in all types of water- natural and treated drinking water for process and cooling water to the water for use in food production and pharmaceuticals.'
        ]
    },
    {
        id: uuidv4(),
        heading: 'Supply of biomedical waste handling material.',
        btnText: 'Learn More',
        link: '/services',
        items: [
            '1. Colour coded Non Chlorinated Poly ethylene bags with QR codes for handling biomedical waste.',
            '2. Colour coded bins of different sizes',
            '3. Puncture proof containers for handling sharp material',
            '4. Posters creating awareness regarding segregation of bio medical waste'
        ]
    },
    {
        id: uuidv4(),
        heading: 'Turnkey Projects',
        btnText: 'Learn More',
        link: '/services',
        items: [
            'Turnkey refers to something that is ready for immediate use, generally used in the sale or supply of goods or services. The word is a reference to the fact that the customer, upon receiving the product, just needs to turn the ignition key to make it operational, or that the key just needs to be turned over to the customer.[2] Turnkey is commonly used in the construction industry, for instance, in which it refers to bundling of materials and labour by the home builder or general contractor to complete the home without owner involvement. The word is often used to describe a home built on the developers land with the developers financing ready for the customer to move in. If a contractor builds a "turnkey home" it frames the structure and finish the interior; everything is completed down to the cabinets and carpet. Turnkey is also commonly used in motorsports to describe a car being sold with powertrain (engine, transmission, etc.) to contrast with a vehicle sold without one so that other components may be re-used.'
        ]
    },

    {
        id: uuidv4(),
        heading: 'Tertiary Treatment Plants.',
        btnText: 'Learn More',
        link: '/services',
        items: [
            'Tertiary treatment is the next wastewater treatment process after secondary treatment. This step removes stubborn contaminants that secondary treatment was not able to clean up. Wastewater effluent becomes even cleaner in this treatment process through the use of stronger and more advanced treatment systems. Tertiary treatment technologies can be the extensions of conventional secondary biological treatment to further stabilize oxygen-demanding substances in the wastewater or to remove nitrogen and phosphorus. Tertiary treatment may also involve physical-chemical separation techniques such as carbon adsorption, flocculation/precipitation, membranes for advanced filtration, ion exchange, dechlorination, and reverse osmosis.'
        ]
    },
    
];

export default servicesOneData;
