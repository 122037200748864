import React from 'react';
import { CgChevronDown, CgChevronLeft } from 'react-icons/cg';

const menuData = [
    {
        title: 'Home',
        path: '/',
    },

    {
        title: 'Products',
        path: '#',
        iconClosed: <CgChevronLeft />,
        iconOpened: <CgChevronDown />,

        subNav: [
            {
                title: 'CliniMACS System',
                path: '/PlateletRichPlasmaKits',
                cName: 'sub-nav',
            },
            {
                title: 'MACS GMP Products',
                path: '/gmpProducts',
            },
            {
                title: 'Transfusion / Transplantation Medicine',
                path: '/transplantationMedicine',
            },
            {
                title: 'Cord Blood Banking',
                path: '/cordBloodBankingKits',
            },
            {
                title: 'Tissue / Cell Processing ',
                path: '/tissueCellProcessing',
            },
            
        ],
    },

    {
        title: 'Services & Applications',
        path: '#',

        iconClosed: <CgChevronLeft />,
        iconOpened: <CgChevronDown />,

        subNav: [
            {
                title: 'Clinical Applications',
                path: '/clinical-applications',
                cName: 'sub-nav',
            },
            {
                title: 'cGMP Products',
                path: '/cgmp-Products',
            },
            {
                title: 'Turn Key Projects',
                path: '/turnKeyProject',
            },
            {
                title: 'Cell and gene therapy',
                path: '/CellGeneTherapy',
            },
        ],
    },

    {
        title: 'News / Events',
        path: '/news',
    },

    {
        title: 'Downloads',
        path: '/downloads',
    },

    {
        title: 'About Us',
        path: '/about',
    },

    {
        title: 'Contact Us',
        path: '/contact',
    },
    
];

export default menuData;
