import React from 'react';
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.min.css';
import kee1 from '../../assets/img/banners/banner-1.jpg';
import kee2 from '../../assets/img/banners/banner-2.jpg';
import kee3 from '../../assets/img/banners/banner-3.jpg';
import kee4 from '../../assets/img/banners/banner-4.jpg';
import kee5 from '../../assets/img/banners/banner-5.jpg';
import kee6 from '../../assets/img/banners/banner-6.jpg';
import kee7 from '../../assets/img/banners/banner-7.jpg';
import kee8 from '../../assets/img/banners/banner-8.jpg';
import kee9 from '../../assets/img/banners/banner-9.jpg';
import kee10 from '../../assets/img/banners/banner-10.jpg';

function Hero1() {
    var images = [kee1, kee2, kee3, kee4, kee5, kee6, kee7, kee8, kee9, kee10];

    var slides = images.map(function (image, i) {
        return <SwiperSlide key={i}>
            <div className="hero-contents" style={{}}>
                <img src={image} />
            </div>
        </SwiperSlide>
    })

    return (
        <section className="hero-slide-wrapper hero-1">
            <Swiper autoplay={{
                delay: 5000,
                disableOnInteraction: true,
            }}
                pagination={{
                    clickable: true
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                loop={true}
                className="myslider">
                {slides}
            </Swiper>
        </section>
    );
}

export default Hero1;
