import React from 'react';
import { FaTh } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { Link } from 'react-router-dom';

function MainMenu({ hanldeOffset }) {
    return (
        <>
            <div className="main-menu-area">
                <div className="row align-center">
                    <div className="col-6 col-md-9 col-lg-12">
                        <div className="main-menu d-flex align-rights">
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/clients">Clients</Link>
                                </li>
                                <li>
                                    <Link to="/projects">Projects</Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/amc-projects">Annual Maintenance Contracts </Link>
                                        </li>
                                        <li>
                                            <Link to="/projects">Constructions, Installations & Commissioning's  </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/services">Services</Link>
                                </li>
                                
                                <li>
                                    <Link to="/contact">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="col-6 col-md-8 col-lg-1 icon-wrapper">
                        <button type="button" onClick={hanldeOffset} className="side-menu-toggle">
                            <FaTh />
                        </button>
                    </div> */}
                </div>
            </div>
        </>
    );
}

export default MainMenu;
