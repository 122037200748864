import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { BiMap } from 'react-icons/bi';
import { v4 as uuidv4 } from 'uuid';

const WidgetGetinTouchData = [
    {
        id: uuidv4(),
        icon: <AiOutlinePhone />,
        heading: 'Phone Number',
        text: '+91 9849009040',
    },
    {
        id: uuidv4(),
        icon: <AiOutlineMail />,
        heading: 'Email',
        text: 'chandukee@gmail.com',
    },
];

export default WidgetGetinTouchData;
