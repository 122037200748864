import React from 'react';
import ContactForm from '../components/ContactForm';
import ContactUs from '../components/ContactUs';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';

function Contact() {
    return (
        <>
            <Header1 />
            <section className='blog-wrapper news-wrapper section-padding pb-0'>
                <div className='container'>
                    <h2>Contact Us</h2>
                </div>
            </section>
            <ContactUs />
            <ContactForm title="Write Message" heading="Get In Touch" />
            <Footer1 />
        </>
    );
}

export default Contact;
