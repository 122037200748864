import React from 'react';
import Client1 from '../assets/img/clients/ghmc.jpg';
import Client2 from '../assets/img/clients/gwmc.jpg';
import Client3 from '../assets/img/clients/hyderabad-univercity.jpg';
import Client4 from '../assets/img/clients/itda-mulugu.jpg';
import Client5 from '../assets/img/clients/mint.jpg';
import Client6 from '../assets/img/clients/nit-warangal.jpg';
import Client7 from '../assets/img/clients/noveltech.jpg';
import Client8 from '../assets/img/clients/south-central-rail.jpg';
import Client9 from '../assets/img/clients/ts-forest-department.jpg';
import Client10 from '../assets/img/clients/tsmsidc.jpg';
import Client11 from '../assets/img/clients/healthcare-fas.jpg';

import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';

function Downloads() {
    return (
        <>
            <Header1 />
            <section className='blog-wrapper news-wrapper section-padding clients'>
                <div className='container'>
                    <div className='row d-flex g-2'>
                        <div className="col-md-3">
                            <div class="card align-self-stretch">
                                <img class="card-img-top" src={Client1} alt="{Client1}" />
                                <div class="card-body">
                                    <h5 class="card-title">Greater Hyderabad Municipal Corporation.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div class="card align-self-stretch">
                                <img class="card-img-top" src={Client2} alt="{Client2}" />
                                <div class="card-body">
                                    <h5 class="card-title">Greater Warangal Municipal Corporation.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div class="card align-self-stretch">
                                <img class="card-img-top" src={Client3} alt="{Client3}" />
                                <div class="card-body">
                                    <h5 class="card-title">Hyderabad Central University, Gachibowli.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div class="card align-self-stretch">
                                <img class="card-img-top" src={Client4} alt="{Client4}" />
                                <div class="card-body">
                                    <h5 class="card-title">ITDA, Mulugu.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div class="card align-self-stretch">
                                <img class="card-img-top" src={Client5} alt="{Client5}" />
                                <div class="card-body">
                                    <h5 class="card-title">MINT, Cherlapally Hyderabad.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div class="card align-self-stretch">
                                <img class="card-img-top" src={Client6} alt="{Client6}" />
                                <div class="card-body">
                                    <h5 class="card-title">National Institute of Technology, Warangal</h5>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-3">
                            <div class="card align-self-stretch">
                                <img class="card-img-top" src={Client8} alt="{Client8}" />
                                <div class="card-body">
                                    <h5 class="card-title">South Central Railway.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div class="card align-self-stretch">
                                <img class="card-img-top" src={Client9} alt="{Client9}" />
                                <div class="card-body">
                                    <h5 class="card-title">Forest Department, Hanamkonda & Jangoan.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div class="card align-self-stretch">
                                <img class="card-img-top" src={Client10} alt="{Client10}" />
                                <div class="card-body">
                                    <h5 class="card-title">TSMSIDC</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div class="card align-self-stretch">
                                <img class="card-img-top" src={Client7} alt="{Client7}" />
                                <div class="card-body">
                                    <h5 class="card-title">Noveltech Feeds Private Limited (Goldman Sachs), Uppugal, Ghanpur.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div class="card align-self-stretch">
                                <img class="card-img-top" src={Client11} alt="{Client11}" />
                                <div class="card-body">
                                    <h5 class="card-title">All Government and Private Health Care Facility in and around Warangal district.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer1 />
        </>
    );
}

export default Downloads;
