import React from 'react';
import AboutFeatured from '../components/AboutFeatured';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';

function About() {
    return (
        <>
            <Header1 />
            <section className='blog-wrapper news-wrapper section-padding'>
                <div className='container'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-heading text-center" style={{marginTop: "2%"}}>
                                <h4>Kakatiya Enviro Engineers <small>Environmental Engineers and Civil Contractors</small></h4>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12">
                            <div className="w-100 p-3">
                                <p className="mb-3 text-justify">
                                Our organization M/s. Kakatiya Enviro Engineers located in Hanamkonda district, Telangana State has been launched in the year 2010. The strong keys of our organization are the established network, the availability of strong experience, stable and sustained development of our organization.
                                </p>
                                <p className="mb-3 text-justify">
                                We, M/s. Kakatiya Enviro Engineers, an environmental consultancy with highly qualified and experienced professionals in the field of civil, environmental, Bio-Medical Waste Management and electro-mechanical sectors since 2010. We have built and provided our O&M services for several Sewage and Effluent Treatment Plants for various institutions and industries.
                                </p>
                                <p className="mb-3 text-justify">
                                We are proud to inform you that we have been providing biomedical waste management services in the name of M/s. Kakatiya Mediclean Services (a unit of Kakatiya Enviro Engineers) for all the Health Care Facilities in erstwhile Warangal district since 2001. 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="divider my-2"></div>
                </div>
            </section>
            <AboutFeatured />
            {/* <p className='text-center mb-5'>
                <strong>Stem cell therapy - Transfusion Medicine - Transplantation <br />Bio Banking - cGMP Products</strong>
            </p> */}
            <Footer1 />
        </>
    );
}

export default About;
