import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollIndicator from './components/ScrollIndicator';
import ScrollToTopRoute from './components/ScrollTopRoute';
import About from './pages/About';
import Contact from './pages/Contact';
import Home1 from './pages/Home1';
import Projects from './pages/Projects';
import AmcProjects from './pages/amc-projects';
import Clients from './pages/clients';
import Services from './pages/services';

function App() {
    return (
        <>
            <Router>
                <ScrollToTopRoute />
                <Switch>
                   
                    <Route exact path="/about">
                        <About />
                    </Route>
                    <Route exact path="/projects">
                        <Projects />
                    </Route>
                    <Route exact path="/amc-projects">
                        <AmcProjects />
                    </Route>
                    <Route exact path="/clients">
                        <Clients />
                    </Route>
                    
                    <Route exact path="/contact">
                        <Contact />
                    </Route>
                    <Route exact path="/services">
                        <Services />
                    </Route>
                    
                    <Route exact path="/">
                        <Home1 />
                    </Route>
                </Switch>
            </Router>
            <ScrollIndicator />
        </>
    );
}

export default App;
