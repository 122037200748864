import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/kee-logo.svg';
import MobileMenu from '../MobileMenu';
import OffsetMenu from '../OffsetMenu';
import MainMenu from './MainMenu';
import Topbar from './Topbar';

function Header1() {
    const [offset, setOffset] = useState(false);
    const handleOffset = () => {
        setOffset(!offset);
    };
    return (
        <>
            <OffsetMenu
                handleOffset={handleOffset}
                className={offset ? 'offset-menu show' : 'offset-menu'}
            />
            <header className="header-wrap header-1">
                <Topbar />
                <div className="container main-header">
                    <div className="row align-items-center">
                        <div className="col-8 col-lg-1">
                            <div className="logo">
                                <Link to="/">
                                    <img src={Logo} alt="logo" width="70"/>
                                </Link>
                            </div>
                        </div>
                        <div className="col-4 d-block d-lg-none">
                            <div className="mobile-nav-wrap">
                                <MobileMenu />
                            </div>
                        </div>
                        <div className="col-12 d-none d-md-block col-lg-11">
                        <MainMenu hanldeOffset={handleOffset} />
                        </div>
                    </div>
                </div>

                {/* <div className="menu-wrapper d-none d-lg-block">
                    <div className="container">
                        <MainMenu hanldeOffset={handleOffset} />
                    </div>
                </div> */}
            </header>
        </>
    );
}

export default Header1;
