/* eslint-disable jsx-a11y/label-has-associated-control */
import Parser from 'html-react-parser';
import React, { useRef, useState }  from 'react';
import FormInput from './FormInput';
import emailjs from '@emailjs/browser';

function ContactForm({ title, heading }) {
    const form = useRef();

    let message = ""

    const [statusMessage, setStatusMessage] = useState("");
    

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_dc7n73c', 'template_t7qet4g', form.current, 'SEaITxA8S_9PA7C2V')
            .then((result) => {
                setStatusMessage(`<h4 className='text-success message-text text-center'>Thank you for contacting kakatiyaenviro.com. We will get back in touch with you soon!</h4>`);
                setTimeout(function () {
                    // window.location.reload(true);
                    setStatusMessage(``)
                  }, 5000);

            }, (error) => {
                setStatusMessage(`<div className='text-error message-text'>${error.text}</div>`);
            });
    };    


    return (
        <section className="contact-form-wrapper section-padding pt-3">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h1>{heading}</h1>
                    </div>

                    <div className="col-12 col-lg-12">
                        <div className="contact-form">
                            <form  ref={form} onSubmit={sendEmail} id="contact">
                                <div className="clearfix"></div>
                                {Parser(statusMessage)}

                                {!statusMessage && <div className="row wrap-form" id="contact-form">
                                    <div className="form-group col-md-6 col-sm-6 my-1">
                                        <h6>Name <span className="primary">*</span></h6>
                                        <input type="text" name="name" id="contact_name" className="form-control input-lg required" placeholder="Enter your full Name" />
                                        <span data-for="name" className="text-error d-none">Required name</span>
                                    </div>
                                    <div className="form-group col-md-6 col-sm-6 my-1">
                                        <h6>Email <span className="primary">*</span></h6>
                                        <input type="text" name="email" id="contact_email" className="form-control input-lg required" placeholder="Enter your email" />
                                        <span data-for="name" className="text-error d-none"> Required valid Email</span>
                                    </div>
                                    <div className="form-group col-md-6 col-sm-6 my-1">
                                        <h6>Mobile <span className="primary">*</span></h6>
                                        <input type="number" name="mobile" maxLength = "10" id="contact_mobile" className="form-control input-lg required" placeholder="Enter your mobile" />
                                        <span data-for="name" className="text-error d-none">Required valid mobile number</span>
                                    </div>
                                    <div className="form-group col-md-6 col-sm-6 my-1">
                                        <h6>Organization <span className="primary">*</span></h6>
                                        <input type="text" name="organization" id="contact_organization" className="form-control input-lg required" placeholder="Enter your organization name" />
                                        <span data-for="email" className="text-error d-none"> Required Organization</span>
                                    </div>
                                    <div className="form-group col-md-6 col-sm-6 my-1">
                                        <h6>Subject <span className="primary">*</span></h6>
                                        <input type="text" name="subject" id="contact_subject" className="form-control input-lg required" placeholder="Enter your subject" />
                                        <span data-for="email" className="text-error d-none">Required Subject</span>
                                    </div>
                                    <div className="form-group col-md-6 col-sm-6 my-1">
                                        <h6>Address <span className="primary">*</span></h6>
                                        <input type="text" name="address1" id="contact_address1" className="form-control input-lg required" placeholder="Enter your address" />
                                        <span data-for="email" className="text-error d-none"> Required Address</span>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <h6>Your Message <span className="primary">*</span></h6>
                                        <textarea name="message" id="contact_message" className="form-control input-lg required" placeholder="Write your requirement" rows="9"></textarea>
                                        <span data-for="message" className="text-error d-none"> Required Message</span>
                                    </div>
                                    <div className="form-group col-md-12 mt-3" id="contact_submit">
                                        <button type="submit" id="submit" className="submit-btn">Send Message</button>
                                        <div className="status-progress"></div>
                                    </div>
                                </div> }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactForm;
