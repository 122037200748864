import React from 'react';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';

import Amc_noveltech1 from '../assets/img/project/amc/50-kld-etp-noveltech-feeds-pvt-ltd/10noveltech-feeds.jpg';
import Amc_noveltech2 from '../assets/img/project/amc/50-kld-etp-noveltech-feeds-pvt-ltd/9noveltech-feeds.jpg';
import Amc_noveltech3 from '../assets/img/project/amc/50-kld-etp-noveltech-feeds-pvt-ltd/8noveltech-feeds.jpg';
import Amc_noveltech4 from '../assets/img/project/amc/50-kld-etp-noveltech-feeds-pvt-ltd/7noveltech-feeds.jpg';
import Amc_noveltech5 from '../assets/img/project/amc/50-kld-etp-noveltech-feeds-pvt-ltd/6noveltech-feeds.jpg';
import Amc_noveltech6 from '../assets/img/project/amc/50-kld-etp-noveltech-feeds-pvt-ltd/5noveltech-feeds.jpg';
import Amc_noveltech7 from '../assets/img/project/amc/50-kld-etp-noveltech-feeds-pvt-ltd/4noveltech-feeds.jpg';
import Amc_noveltech8 from '../assets/img/project/amc/50-kld-etp-noveltech-feeds-pvt-ltd/3noveltech-feeds.jpg';
import Amc_noveltech9 from '../assets/img/project/amc/50-kld-etp-noveltech-feeds-pvt-ltd/2noveltech-feeds.jpg';
import Amc_noveltech10 from '../assets/img/project/amc/50-kld-etp-noveltech-feeds-pvt-ltd/1noveltech-feeds.jpg';
import Amc_uoh1 from '../assets/img/project/amc/650-kld-johkasou-technology-stps-at-hyderabad-central-university/1uoh.jpg';
import Amc_uoh2 from '../assets/img/project/amc/650-kld-johkasou-technology-stps-at-hyderabad-central-university/2uoh.jpg';
import Amc_uoh3 from '../assets/img/project/amc/650-kld-johkasou-technology-stps-at-hyderabad-central-university/3uoh.jpg';
import Amc_uoh4 from '../assets/img/project/amc/650-kld-johkasou-technology-stps-at-hyderabad-central-university/4uoh.jpg';
import Amc_uoh5 from '../assets/img/project/amc/650-kld-johkasou-technology-stps-at-hyderabad-central-university/5uoh.jpg';
import Amc_uoh6 from '../assets/img/project/amc/650-kld-johkasou-technology-stps-at-hyderabad-central-university/6uoh.jpg';
import Amc_uoh7 from '../assets/img/project/amc/650-kld-johkasou-technology-stps-at-hyderabad-central-university/7uoh.jpg';
import Amc_uoh8 from '../assets/img/project/amc/650-kld-johkasou-technology-stps-at-hyderabad-central-university/8uoh.jpg';
import Amc_uoh9 from '../assets/img/project/amc/650-kld-johkasou-technology-stps-at-hyderabad-central-university/9uoh.jpg';
import Amc_uoh10 from '../assets/img/project/amc/650-kld-johkasou-technology-stps-at-hyderabad-central-university/10uoh.jpg';
import Amc_uoh11 from '../assets/img/project/amc/650-kld-johkasou-technology-stps-at-hyderabad-central-university/11uoh.jpg';
import Amc_uoh12 from '../assets/img/project/amc/650-kld-johkasou-technology-stps-at-hyderabad-central-university/12uoh.jpg';
import Amc_uoh13 from '../assets/img/project/amc/650-kld-johkasou-technology-stps-at-hyderabad-central-university/13uoh.jpg';
import Amc_uoh14 from '../assets/img/project/amc/650-kld-johkasou-technology-stps-at-hyderabad-central-university/14uoh.jpg';
import Amc_nit1 from '../assets/img/project/amc/650-kld-mbbr-based-stp-at-nit-warangal/1nit-warangal.jpg';
import Amc_nit2 from '../assets/img/project/amc/650-kld-mbbr-based-stp-at-nit-warangal/2nit-warangal.jpg';
import Amc_nit3 from '../assets/img/project/amc/650-kld-mbbr-based-stp-at-nit-warangal/3nit-warangal.jpg';
import Amc_nit4 from '../assets/img/project/amc/650-kld-mbbr-based-stp-at-nit-warangal/4nit-warangal.jpg';
import Amc_nit5 from '../assets/img/project/amc/650-kld-mbbr-based-stp-at-nit-warangal/5nit-warangal.jpg';
import Amc_nit6 from '../assets/img/project/amc/650-kld-mbbr-based-stp-at-nit-warangal/6nit-warangal.jpg';
import Amc_nit7 from '../assets/img/project/amc/650-kld-mbbr-based-stp-at-nit-warangal/7nit-warangal.jpg';
import Amc_nit8 from '../assets/img/project/amc/650-kld-mbbr-based-stp-at-nit-warangal/8nit-warangal.jpg';
import Amc_nit9 from '../assets/img/project/amc/650-kld-mbbr-based-stp-at-nit-warangal/9nit-warangal.jpg';
import Amc_nit10 from '../assets/img/project/amc/650-kld-mbbr-based-stp-at-nit-warangal/10nit-warangal.jpg';
import Amc_nit11 from '../assets/img/project/amc/650-kld-mbbr-based-stp-at-nit-warangal/11nit-warangal.jpg';
import Amc_nit12 from '../assets/img/project/amc/650-kld-mbbr-based-stp-at-nit-warangal/12nit-warangal.jpg';
import Amc_mint from '../assets/img/project/amc/etp-mint-cherlapally/download.jpg';
import Amc_zoo1 from '../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-1.jpg';
import Amc_zoo2 from '../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-2.jpg';
import Amc_zoo3 from '../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-3.jpg';
import Amc_zoo4 from '../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-4.jpg';
import Amc_zoo5 from '../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-5.jpg';
import Amc_zoo6 from '../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-6.jpg';
import Amc_zoo7 from '../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-7.jpg';
import Amc_zoo8 from '../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-8.jpg';
import Amc_zoo9 from '../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-9.jpg';
import Amc_zoo10 from '../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-10.jpg';
import Amc_zoo11 from '../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-11.jpg';
import Amc_zoo12 from '../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-12.jpg';
import Amc_zoo13 from '../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-13.jpg';
import Amc_zoo14 from '../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-14.jpg';
import Amc_zoo15 from '../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-15.jpg';
import Amc_zoo16 from '../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-16.jpg';
import Amc_zoo17 from '../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-17.jpg';


function Projects() {
    return (
        <>
            <Header1 />
            <section className='blog-wrapper news-wrapper section-padding'>

                <div className='container'>
                    <div class="row">
                        <div class="col-12 col-lg-12">
                            <div class="section-title text-center">
                                <h3>Annual Maintenance Contracts</h3>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-12">
                            <div className="news-content">
                                <div className="section-heading marginbot20">
                                    <p><strong>50 KLD ETP, </strong>Noveltech Feeds Pvt Ltd:</p><h5></h5>
                                </div>
                                <div className="section-body d-flex flex-wrap g-2">
                                    <div className="row">
                                        <div className="col-4"><img src={Amc_noveltech1} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_noveltech2} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_noveltech3} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_noveltech4} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_noveltech5} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_noveltech6} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_noveltech7} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_noveltech8} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_noveltech9} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_noveltech10} class="img-thumbnail" /></div>
                                    </div>
                                </div>
                                <div className="divider my-2"></div>

                                <div className="section-heading marginbot20">
                                    <p><strong>650 KLD Johkasou Technology STPs</strong> at Hyderabad Central University:</p><h5></h5>
                                </div>
                                <div className="section-body d-flex flex-wrap g-2">
                                    <div className="row">
                                        <div className="col-4"><img src={Amc_uoh1} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_uoh2} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_uoh3} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_uoh4} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_uoh5} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_uoh6} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_uoh7} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_uoh8} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_uoh9} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_uoh10} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_uoh11} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_uoh12} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_uoh13} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_uoh14} class="img-thumbnail" /></div>
                                    </div>
                                </div>
                                <div className="divider my-2"></div>

                                
                                <div className="section-heading marginbot20">
                                    <p><strong>650 KLD MBBR</strong>  based STP at NIT, Warangal:</p><h5></h5>
                                </div>
                                <div className="section-body d-flex flex-wrap g-2">
                                    <div className="row">
                                        <div className="col-4"><img src={Amc_nit1} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_nit2} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_nit3} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_nit4} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_nit5} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_nit6} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_nit7} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_nit8} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_nit9} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_nit10} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_nit11} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_nit12} class="img-thumbnail" /></div>
                                    </div>
                                </div>
                                <div className="divider my-2"></div>

                                <div className="section-heading marginbot20">
                                    <p><strong>ETP, MINT </strong>Cherlapally:</p><h5></h5>
                                </div>
                                <div className="section-body d-flex flex-wrap g-2">
                                    <div className="row">
                                        <div className="col-4"><img src={Amc_mint} width="1200" class="img-thumbnail" /></div>
                                    </div>
                                </div>
                                <div className="divider my-2"></div>

                                <div className="section-heading marginbot20">
                                    <p><strong>Kakatiya Zoological Park</strong> Maintenance:</p><h5></h5>
                                </div>
                                <div className="section-body d-flex flex-wrap g-2">
                                    <div className="row">
                                        <div className="col-4"><img src={Amc_zoo1} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_zoo2} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_zoo3} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_zoo4} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_zoo5} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_zoo6} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_zoo7} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_zoo8} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_zoo9} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_zoo10} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_zoo11} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_zoo12} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_zoo13} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_zoo14} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_zoo15} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_zoo16} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Amc_zoo17} class="img-thumbnail" /></div>
                                    </div>
                                </div>
                                <div className="divider my-2"></div>

                                

                            </div>
                        </div>
                    </div>


                </div>
            </section>
            <Footer1 />
        </>
    );
}

export default Projects;
