import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function ServicesOneCard({ bgImg, icon, heading, btnText, link, list }) {

    const numbers = list;
    const listItems = numbers.map((number) =>
    <li className='li-arrow'>{number}</li>
    );

    return (
        <div className="col-md-6 col-xl-4 col-12">
            <Link to={link}>
                <div className="single-service-item service-1 mt-3">
                    <div
                        className="service-bg bg-cover"
                    />
                    <h3 className='mb-2'>{heading}</h3>
                    {/* <p style={{"textAlign": "left"}}>
                        {listItems}
                    </p> */}
                </div>
            </Link>
        </div>
    );
}

export default ServicesOneCard;
