import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function FilterItem({ image, link, heading }) {
    return (
        <div className="col-xl-4 col-md-6 grid-item bedroom">
            <div className="single-cause-item">
                <div className="cause-bg bg-cover" style={{ backgroundImage: `url(${image})` }}>
                    {/* {
                        link ? (
                            <Link to={link} className="icon">
                                <BsArrowRight />
                            </Link>
                            ) : (
                                <span></span>
                            )
                    } */}
                </div>
                <div className="cause-content">
                    <h4>
                        <Link to={link}>{heading} {link ? (<span className="link"><BsArrowRight /></span>) : (<span></span>)}</Link>
                    </h4>
                </div>
            </div>
        </div>
    );
}

export default FilterItem;
