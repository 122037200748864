import { v4 as uuidv4 } from 'uuid';
import img1 from '../../assets/img/project/amc/50-kld-etp-noveltech-feeds-pvt-ltd/10noveltech-feeds.jpg';
import img2 from '../../assets/img/project/amc/650-kld-johkasou-technology-stps-at-hyderabad-central-university/1uoh.jpg';
import img3 from '../../assets/img/project/amc/650-kld-mbbr-based-stp-at-nit-warangal/1nit-warangal.jpg';
import img4 from '../../assets/img/project/amc/etp-mint-cherlapally/download.jpg';
import img5 from '../../assets/img/project/amc/kakatiya-zoological-park-maintenance/kakatiya-zoo-1.jpg';
import img6 from '../../assets/img/project/cic/100-kld-mbbr-based-stp-at-abdullapurmet-hyderabad/abdullapurmet-1.jpg';
import img7 from '../../assets/img/project/cic/25-kld-mbbr-based-stp-at-medaram/medaram-1.jpg';
import img8 from '../../assets/img/project/cic/400-kld-mbbr-based-stp-at-jawaharnagar-hyderabad/jawaharnaga-1.jpg';
import img9 from '../../assets/img/project/cic/50-kld-sbr-based-stp-at-south-central-railway-kazipet/kazipet-1.jpg';
import img10 from '../../assets/img/project/cic/750-kld-sbt-based-stp-at-mgm-hospital-warangal/mgm-hosp-1.jpg';
import img11 from '../../assets/img/project/cic/Pakal/pakal-1.jpg';
import img12 from '../../assets/img/project/cic/bow-bridge-contruction-zoopar-warangal/zooper-1.jpg';



const projectFilterData = [
    {
        id: uuidv4(),
        image: img1,
        heading: '50 KLD ETP, Noveltech Feeds Pvt Ltd',
        category: 'Annual Maintenance Contracts',
        link: "/amc-projects"
    },

    {
        id: uuidv4(),
        image: img2,
        heading: '650 KLD Johkasou Technology STPs at Hyderabad Central University',
        category: 'Annual Maintenance Contracts',
        link: "/amc-projects"
    },
    
    {
        id: uuidv4(),
        image: img3,
        heading: '650 KLD MBBR based STP at NIT, Warangal',
        category: 'Annual Maintenance Contracts',
        link: "/amc-projects"
    },

    {
        id: uuidv4(),
        image: img4,
        heading: 'ETP, MINT Cherlapally',
        category: 'Annual Maintenance Contracts',
        link: "/amc-projects"
    },

    {
        id: uuidv4(),
        image: img5,
        heading: 'Kakatiya Zoological Park Maintenance',
        category: 'Annual Maintenance Contracts',
        link: "/amc-projects"
    },

    {
        id: uuidv4(),
        image: img6,
        heading: '25 KLD MBBR based STP at Medaram',
        category: 'Constructions, Installations & Commissionings',
        link: "/projects"
    },

    {
        id: uuidv4(),
        image: img7,
        heading: '50 KLD SBR based STP at South Central Railway, Kazipet',
        category: 'Constructions, Installations & Commissionings',
        link: "/projects"
    },

    {
        id: uuidv4(),
        image: img8,
        heading: '100 KLD MBBR based STP at Abdullapurmet, Hyderabad',
        category: 'Constructions, Installations & Commissionings',
        link: "/projects"
    },
    {
        id: uuidv4(),
        image: img9,
        heading: '400 KLD MBBR based STP at Jawaharnagar, Hyderabad',
        category: 'Constructions, Installations & Commissionings',
        link: "/projects"
    },
    {
        id: uuidv4(),
        image: img10,
        heading: '750 KLD SBT based STP at MGM Hosp., Warangal',
        category: 'Constructions, Installations & Commissionings',
        link: "/projects"
    },
    {
        id: uuidv4(),
        image: img11,
        heading: 'Bow Bridge Contruction, Zoopar, Warangal',
        category: 'Constructions, Installations & Commissionings',
        link: "/projects"
    },
    {
        id: uuidv4(),
        image: img12,
        heading: 'Pakal',
        category: 'Constructions, Installations & Commissionings',
        link: "/projects"
    }
];

export default projectFilterData;
