import React from 'react';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import Cic_abdul1 from '../assets/img/project/cic/100-kld-mbbr-based-stp-at-abdullapurmet-hyderabad/abdullapurmet-1.jpg';
import Cic_abdul2 from '../assets/img/project/cic/100-kld-mbbr-based-stp-at-abdullapurmet-hyderabad/abdullapurmet-2.jpg';
import Cic_abdul3 from '../assets/img/project/cic/100-kld-mbbr-based-stp-at-abdullapurmet-hyderabad/abdullapurmet-3.jpg';
import Cic_abdul4 from '../assets/img/project/cic/100-kld-mbbr-based-stp-at-abdullapurmet-hyderabad/abdullapurmet-4.jpg';
import Cic_abdul5 from '../assets/img/project/cic/100-kld-mbbr-based-stp-at-abdullapurmet-hyderabad/abdullapurmet-5.jpg';
import Cic_abdul6 from '../assets/img/project/cic/100-kld-mbbr-based-stp-at-abdullapurmet-hyderabad/abdullapurmet-6.jpg';
import Cic_abdul7 from '../assets/img/project/cic/100-kld-mbbr-based-stp-at-abdullapurmet-hyderabad/abdullapurmet-7.jpg';
import Cic_abdul8 from '../assets/img/project/cic/100-kld-mbbr-based-stp-at-abdullapurmet-hyderabad/abdullapurmet-8.jpg';
import Cic_abdul9 from '../assets/img/project/cic/100-kld-mbbr-based-stp-at-abdullapurmet-hyderabad/abdullapurmet-9.jpg';
import Cic_abdul10 from '../assets/img/project/cic/100-kld-mbbr-based-stp-at-abdullapurmet-hyderabad/abdullapurmet-10.jpg';
import Cic_medaram1 from '../assets/img/project/cic/25-kld-mbbr-based-stp-at-medaram/medaram-1.jpg';
import Cic_medaram2 from '../assets/img/project/cic/25-kld-mbbr-based-stp-at-medaram/medaram-2.jpg';
import Cic_medaram3 from '../assets/img/project/cic/25-kld-mbbr-based-stp-at-medaram/medaram-3.jpg';
import Cic_medaram4 from '../assets/img/project/cic/25-kld-mbbr-based-stp-at-medaram/medaram-4.jpg';
import Cic_medaram5 from '../assets/img/project/cic/25-kld-mbbr-based-stp-at-medaram/medaram-5.jpg';
import Cic_jawahar1 from '../assets/img/project/cic/400-kld-mbbr-based-stp-at-jawaharnagar-hyderabad/jawaharnaga-1.jpg';
import Cic_jawahar2 from '../assets/img/project/cic/400-kld-mbbr-based-stp-at-jawaharnagar-hyderabad/jawaharnaga-2.jpg';
import Cic_south1 from '../assets/img/project/cic/50-kld-sbr-based-stp-at-south-central-railway-kazipet/kazipet-1.jpg';
import Cic_south2 from '../assets/img/project/cic/50-kld-sbr-based-stp-at-south-central-railway-kazipet/kazipet-2.jpg';
import Cic_south3 from '../assets/img/project/cic/50-kld-sbr-based-stp-at-south-central-railway-kazipet/kazipet-3.jpg';
import Cic_south4 from '../assets/img/project/cic/50-kld-sbr-based-stp-at-south-central-railway-kazipet/kazipet-4.jpg';
import Cic_south5 from '../assets/img/project/cic/50-kld-sbr-based-stp-at-south-central-railway-kazipet/kazipet-5.jpg';
import Cic_mgm1 from '../assets/img/project/cic/750-kld-sbt-based-stp-at-mgm-hospital-warangal/mgm-hosp-1.jpg';
import Cic_mgm2 from '../assets/img/project/cic/750-kld-sbt-based-stp-at-mgm-hospital-warangal/mgm-hosp-2.jpg';
import Cic_mgm3 from '../assets/img/project/cic/750-kld-sbt-based-stp-at-mgm-hospital-warangal/mgm-hosp-3.jpg';
import Cic_mgm4 from '../assets/img/project/cic/750-kld-sbt-based-stp-at-mgm-hospital-warangal/mgm-hosp-4.jpg';
import Cic_mgm5 from '../assets/img/project/cic/750-kld-sbt-based-stp-at-mgm-hospital-warangal/mgm-hosp-5.jpg';
import Cic_mgm6 from '../assets/img/project/cic/750-kld-sbt-based-stp-at-mgm-hospital-warangal/mgm-hosp-6.jpg';
import Cic_pakal1 from '../assets/img/project/cic/Pakal/pakal-1.jpg';
import Cic_pakal2 from '../assets/img/project/cic/Pakal/pakal-2.jpg';
import Cic_zooper1 from '../assets/img/project/cic/bow-bridge-contruction-zoopar-warangal/zooper-1.jpg';
import Cic_zooper2 from '../assets/img/project/cic/bow-bridge-contruction-zoopar-warangal/zooper-2.jpg';
import Cic_zooper3 from '../assets/img/project/cic/bow-bridge-contruction-zoopar-warangal/zooper-3.jpg';
import Cic_zooper4 from '../assets/img/project/cic/bow-bridge-contruction-zoopar-warangal/zooper-4.jpg';

function Projects() {
    return (
        <>
            <Header1 />
            <section className='blog-wrapper news-wrapper section-padding'>

                <div className='container'>
                    <div class="row">
                        <div class="col-12 col-lg-12">
                            <div class="section-title text-center">
                                <h3>Constructions, Installations & Commissionings</h3>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-12">
                            <div className="news-content">
                                <div className="section-heading marginbot20">
                                    <p><strong>25 KLD MBBR</strong> based STP at Medaram:</p><h5></h5>
                                </div>
                                <div className="section-body d-flex flex-wrap g-2">
                                    <div className="row">
                                        <div className="col-4"><img src={Cic_medaram1} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_medaram2} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_medaram3} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_medaram4} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_medaram5} class="img-thumbnail" /></div>
                                    </div>
                                </div>
                                <div className="divider my-2"></div>

                                <div className="section-heading marginbot20">
                                    <p><strong>50 KLD SBR</strong> based STP at South Central Railway, Kazipet:</p><h5></h5>
                                </div>
                                <div className="section-body d-flex flex-wrap g-2">
                                    <div className="row">
                                        <div className="col-4"><img src={Cic_south1} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_south2} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_south3} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_south4} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_south5} class="img-thumbnail" /></div>
                                    </div>
                                </div>
                                <div className="divider my-2"></div>

                                <div className="section-heading marginbot20">
                                    <p><strong>100 KLD MBBR</strong> based STP at Abdullapurmet, Hyderabad:</p><h5></h5>
                                </div>
                                <div className="section-body d-flex flex-wrap g-2">
                                    <div className="row">
                                        <div className="col-4"><img src={Cic_abdul1} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_abdul2} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_abdul3} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_abdul4} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_abdul5} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_abdul6} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_abdul7} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_abdul8} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_abdul9} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_abdul10} class="img-thumbnail" /></div>
                                    </div>
                                </div>
                                <div className="divider my-2"></div>

                                <div className="section-heading marginbot20">
                                    <p><strong>400 KLD MBBR</strong> based STP at Jawaharnagar, Hyderabad:</p><h5></h5>
                                </div>
                                <div className="section-body d-flex flex-wrap g-2">
                                    <div className="row">
                                        <div className="col-4"><img src={Cic_jawahar1} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_jawahar2} class="img-thumbnail" /></div>\
                                    </div>
                                </div>
                                <div className="divider my-2"></div>

                                <div className="section-heading marginbot20">
                                    <p><strong>750 KLD SBT</strong> based STP at MGM Hospital, Warangal:</p><h5></h5>
                                </div>
                                <div className="section-body d-flex flex-wrap g-2">
                                    <div className="row">
                                        <div className="col-4"><img src={Cic_mgm1} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_mgm2} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_mgm3} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_mgm4} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_mgm5} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_mgm6} class="img-thumbnail" /></div>
                                    </div>
                                </div>
                                <div className="divider my-2"></div>

                                <div className="section-heading marginbot20">
                                    <p><strong>Bow Bridge Contruction,</strong>  Zoopar, Warangal:</p><h5></h5>
                                </div>
                                <div className="section-body d-flex flex-wrap g-2">
                                    <div className="row">
                                        <div className="col-4"><img src={Cic_zooper1} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_zooper2} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_zooper3} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_zooper4} class="img-thumbnail" /></div>
                                    </div>
                                </div>
                                <div className="divider my-2"></div>

                                <div className="section-heading marginbot20">
                                    <p><strong>Pakal:</strong></p><h5></h5>
                                </div>
                                <div className="section-body d-flex flex-wrap g-2">
                                    <div className="row">
                                        <div className="col-4"><img src={Cic_pakal1} class="img-thumbnail" /></div>
                                        <div className="col-4"><img src={Cic_pakal2} class="img-thumbnail" /></div>
                                    </div>
                                </div>
                                <div className="divider my-2"></div>

                            </div>
                        </div>
                    </div>

                    
                </div>
            </section>
            <Footer1 />
        </>
    );
}

export default Projects;
